<template>
    <div>

    </div>
</template>
<script>
import cookie from '@/util/cookie'
import refreshToken from '@/util/refreshToken'
import {login, sendMessage, getUserInfo} from '@/api/accountApi'
import {wwwUrl} from '@/config/config'
export default {
    async mounted () {
        var authToken = cookie.get('authToken') || localStorage.getItem('authToken')
        
        if (!authToken) {
             this.$router.replace('login')
            return
        }
      
        try {
            await refreshToken.refreshToken()
            let userInfo = ''
            if(localStorage.countyUserInfo){
                userInfo = JSON.parse(localStorage.countyUserInfo)
            }
            if(localStorage.userInfo){
                userInfo = JSON.parse(localStorage.userInfo)
            }

            if(!userInfo){
                throw new Error()
                return
            }
             
            let result = await getUserInfo({userName: userInfo.userName})
            this.$store.commit('user/setUserInfo',{info:result.userInfo, redirectUrl: result.redirectUrl})

            var redirectUrl = result.redirectUrl
            
            if(location.host == 'local.fjfuyu.net'){
                redirectUrl = redirectUrl.replace('https://test.luomi.cn/dash', 'http://local.fjfuyu.net')
            }   

            if (redirectUrl) {
                var path = redirectUrl.split('#')[0]
                var hash = redirectUrl.split('#')[1]
                var random = Math.random()
                if(hash){
                    location.href = `${path}?v=${random}#${hash}`
                } else {
                    
                    location.href = `${path}?v=${random}`
                }
                

              
            } else {
                this.$message.error('没有权限，无法访问')
            }
            
        } catch (error) {
            console.log(error)
            this.$router.replace('login')
        }
            
       
    }
}
</script>
<style scoped>

</style>
